import {
  ChannelType,
  Discount,
  OrderType,
  ServiceType,
} from '@open-tender/types'
import {
  makeLocalDateStr,
  formatDateStr,
  makeImageMap,
} from '@open-tender/utils'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import { Tag } from 'components'
import {
  RewardButton,
  RewardChannel,
  RewardContent,
  RewardDash,
  RewardImage,
  RewardNote,
  RewardNoteDefault,
  RewardTag,
  RewardTitle,
  RewardView,
} from './Reward.styled'

const makeServiceType = (serviceType: ServiceType | null) => {
  switch (serviceType) {
    case 'WALKIN':
      return (
        <>
          {/* <RewardNoteIcon>
            <Grid size={14} />
          </RewardNoteIcon> */}
          In-store only
        </>
      )
    case 'PICKUP':
      return (
        <>
          {/* <RewardNoteIcon>
            <ShoppingBag size={14} />
          </RewardNoteIcon> */}
          Pickup only
        </>
      )
    case 'DELIVERY':
      return (
        <>
          {/* <RewardNoteIcon>
            <Truck size={14} />
          </RewardNoteIcon> */}
          Delivery only
        </>
      )
    default:
      return null
  }
}

const makeOrderType = (orderType: OrderType | null) => {
  switch (orderType) {
    case 'OLO':
      return null
    case 'CATERING':
      return 'Catering only'
    case 'MERCH':
      return 'Merch only'
    default:
      return null
  }
}

const makeChannelType = (channelType: ChannelType | null) => {
  switch (channelType) {
    case 'APP':
      return <RewardChannel>App Only!</RewardChannel>
    case 'ONLINE':
      return <RewardChannel>Online Only!</RewardChannel>
    case 'POS':
      return <RewardChannel>At POS Only!</RewardChannel>
    case 'KIOSK':
      return <RewardChannel>Kiosk Only!</RewardChannel>
    default:
      return null
  }
}

export const makeLimitations = (item: Discount) => {
  const { channel_type, order_type, service_type } = item
  const serviceType = makeServiceType(service_type)
  const orderType = makeOrderType(order_type)
  const channelType =
    channel_type && makeChannelType(channel_type as ChannelType)
  const comma = serviceType && orderType ? ', ' : null
  const dash =
    (serviceType || orderType) && channelType ? (
      <RewardDash>-</RewardDash>
    ) : null

  if (serviceType || orderType || channelType) {
    return (
      <>
        {serviceType}
        {comma}
        {orderType}
        {dash}
        {channelType}
      </>
    )
  }
  return (
    <RewardNoteDefault>
      {/* <RewardNoteStarIcon size={14} /> */}
      Valid on all online & app orders
    </RewardNoteDefault>
  )
}

const makeReward = (item: Discount) => {
  const imageMap = makeImageMap(item.images)
  const imageUrl =
    imageMap.SMALL_IMAGE || imageMap.APP_IMAGE || imageMap.LARGE_IMAGE
  const expiration = item.end_date
    ? formatDateStr(item.end_date, 'MMM d')
    : null
  const limitations = makeLimitations(item)
  return { ...item, imageUrl, expiration, limitations }
}

export type RewardWithLimitations = ReturnType<typeof makeReward>

export type RewardEnhanced = Omit<RewardWithLimitations, 'limitations'>

const Reward = ({
  item,
  withoutImage,
}: {
  item: Discount
  withoutImage?: boolean
}) => {
  const dispatch = useAppDispatch()
  const today = makeLocalDateStr(new Date(), 0, 'yyyy-MM-dd')
  const reward = makeReward(item)
  const todayOnly = reward.end_date === today
  const bgStyle = reward.imageUrl
    ? { backgroundImage: `url(${reward.imageUrl}` }
    : undefined

  const redeem = () => {
    const { limitations, ...rest } = reward
    const args = { reward: rest }
    dispatch(openModal({ type: 'reward', args }))
  }

  const expiration = reward.end_date ? (
    reward.end_date === today ? (
      <span>&nbsp;Today only</span>
    ) : reward.end_date ? (
      <span>&nbsp;Use by {reward.expiration}</span>
    ) : null
  ) : null

  return (
    <RewardButton onClick={redeem} label={`Apply ${reward.name}`}>
      <RewardView>
        <RewardTag>
          {todayOnly && (
            <Tag text="Today only!" icon={undefined} color="alert" />
          )}
        </RewardTag>
        {!withoutImage && <RewardImage style={bgStyle} />}
        <RewardContent>
          <RewardTitle as="p" className="title" color="primary">
            {reward.title}
          </RewardTitle>
          <RewardNote as="div" color="tertiary">
            {reward.limitations}
            {expiration}
          </RewardNote>
        </RewardContent>
      </RewardView>
    </RewardButton>
  )
}

export default Reward
